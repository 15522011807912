import React from 'react'
import { graphql } from 'gatsby'

import DefaultLayout from '@hoc/Layout/Default'
import ContentResolver from '@hoc/ContentResolver'
import Meta from '@hoc/Meta'

const Service = ({ data, pageContext }) => {
    const { theme } = pageContext
    const { service } = data

    return (
        <DefaultLayout {...{ theme, headerColor: service?.headerColor }}>
            <Meta {...{ seo: service?.seo }} />
            <ContentResolver {...{ content: service?.content || [], theme }} />
        </DefaultLayout>
    )
}

export const query = graphql`
    query getService($id: String) {
        service: datoCmsService(id: { eq: $id }) {
            id
            slug
            seo {
                description
                title
                twitterCard
                image {
                    url
                }
            }
            headerColor
            content {
                ... on DatoCmsHero {
                    ...Hero
                }
                ... on DatoCmsContactForm {
                    ...ContactForm
                }
                ... on DatoCmsOffsetGrid {
                    ...OffsetGrid
                }
                ... on DatoCmsTextPanel {
                    ...TextPanel
                }
                ... on DatoCmsIconGrid {
                    ...IconGrid
                }
                ... on DatoCmsLogoGrid {
                    ...LogoGrid
                }
                ... on DatoCmsBrowserImage {
                    ...BrowserImage
                }
                ... on DatoCmsQuote {
                    ...Quote
                }
                ... on DatoCmsLinkList {
                    ...LinkList
                }
                ... on DatoCmsImageCarousel {
                    ...ImageCarousel
                }
                ... on DatoCmsEditor {
                    ...Editor
                }
            }
        }
    }
`

export default Service
